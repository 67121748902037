import {
  APP_PERMS,
  CallTranscriptReview,
  CallTranscriptTask,
  QUALIFYING_QUESTION_CATEGORY,
  QUALIFYING_QUESTION_TOPIC,
  QualifyingAnswer,
  QualifyingQuestion,
  UserProfile,
} from 'common.interfaces';
import {
  AvailableRecording,
  CallTranscriptTagDefinition,
  FullTranscriptInfo,
  TagInTranscript,
} from 'common.interfaces';
import { AudioState } from '@origin8-web/core-ui/audio';

export const userStoreKey = 'user';
export const recordingDownloaderStoreKey = 'recordingDownloader';
export const callTranscriptStateKey = 'callTranscript';

export interface RecordingDownloaderState {
  isInCall: boolean;
  isDownloading: boolean;
  availableRecordings: AvailableRecording[];
  downloadedRecordingId: string | undefined;
  connectingToStatusStream: boolean;
}

export enum ARC_TRACK_ID_PREFIX {
  CALL_TRANSCRIPT = 'arc_call_transcript',
}

export type CallTranscriptState = {
  taskId: string;
  fullInfo: Partial<FullTranscriptInfo>;
  audioState: AudioState;
  audioExternalControls: Pick<Partial<AudioState>, 'currentTime' | 'play'> | undefined | null;
  scrollOnAudioPlay: boolean;
  tagDefinitions: CallTranscriptTagDefinition[];
  tagsInTranscript: TagInTranscript[];
  isDraggingTagDefinition: boolean;
  callTranscriptReview: CallTranscriptReview | undefined | null;
  lastTargetedNote: number;
  isLoadingCallTranscript: boolean;
  mustCreateReview: boolean;
  taskInfo: CallTranscriptTask | undefined;
};

export type CallGradingState = {
  qualifyingQuestions: QualifyingQuestion[];
  qualifyingAnswers: QualifyingAnswer[];
  showNotAnswered: boolean;
  filteredCategory: QUALIFYING_QUESTION_CATEGORY | null;
  filteredTopic: QUALIFYING_QUESTION_TOPIC | null;
  isLoadingQualifyingAnswers: boolean;
  expanded: boolean;
  questionIdsBeingAnswered: string[];
};

export interface ArcState {
  user: {
    userInfo: UserProfile | undefined | null;
  };
  [callTranscriptStateKey]: CallTranscriptState;
  [recordingDownloaderStoreKey]: RecordingDownloaderState;
}

export type UserState = ArcState['user'];

export type TagsWithCountByCategory = {
  tagsWithOccurrencesCount: number;
  category: string;
  tags: Array<
    Omit<CallTranscriptTagDefinition, 'timestamp' | 'version'> & {
      occurencesInTranscript: number;
      occurrencesPositionsInTranscript: Array<number>;
    }
  >;
};

export enum TAG_SCROLL_ON {
  SUCCESS = 'success',
  FAILURE = 'failure',
  NEVER = 'never',
}

export const CATEGORY_DROP_ZONE_PREFIX = 'category-drop-zone-';
export const TAG_UTTERANCE_DROP_ZONE_PREFIX = 'tag-utterance-drop-zone-';

export enum CALL_TRANSCRIPT_ROUTE {
  BASE = 'call-transcript',
  BASE_FULL_PATH = '/call-transcript',
}

export enum TRANSCRIPT_GRADE_SUMMARY_ROUTE {
  BASE = 'transcript-grade-summary',
  BASE_FULL_PATH = '/transcript-grade-summary',
}

export enum CALLS_TO_REVIEW_ROUTE {
  BASE = 'calls-to-review',
  BASE_FULL_PATH = '/calls-to-review',
}

export enum MANAGE_TAGS_ROUTE {
  BASE = 'manage-tags',
  BASE_FULL_PATH = '/manage-tags',
}

export enum EDIT_TAG_ROUTE {
  BASE = 'edit-tag',
  BASE_FULL_PATH = '/edit-tag',
}

export enum ADD_NEW_TAG_ROUTE {
  BASE = 'add-new-tag',
  BASE_FULL_PATH = '/add-new-tag',
}

export const UNKNOWN_SPEAKER_NAME = 'Unknown speaker';

export interface GroupedQualifyingQuestionsByTheirTopic {
  id: symbol;
  topic: QUALIFYING_QUESTION_TOPIC;
  questions: QualifyingQuestion[];
  order: number;
}

export const ArcPagesPermissions = {
  callsToReviewPerms: [APP_PERMS.CALL_RECORDING_READ_ALL, APP_PERMS.PIPELINE_READ_ALL],
  manageTagsPerms: [APP_PERMS.CONFIGURATION_ARC_WRITE, APP_PERMS.PIPELINE_READ_ALL],
  dashboardPermissions: [APP_PERMS.CALL_REVIEW_WRITE, APP_PERMS.CALL_REVIEW_READ_ALL, APP_PERMS.PIPELINE_READ_ALL],
};
